/* Main sign in button */
.signin__button {
	padding: 0.5rem 1rem;
	color: #fff;
	background: #FF4820;
	font-family: var(--font-family);
	font-weight: 500;
	font-size: 18px;
	line-height: 25px;
	border-radius: 5px;
	border: none;
	outline: none;
	cursor: pointer;
	margin: 0.5rem;
}

.signin__button:hover {
  background-color: #FF4820;
}

/* Sign in modal */
.signin__modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
	background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.signin__modal-content {
  background-color: #fff;
  padding: 30px;
  border-radius: 5px;
  width: 400px;
  max-width: 90%;
  position: relative;
}

.signin__modal-content p {
  font-size: 1.2rem;
  line-height: 1.5;
  color: #333;
  text-align: center;
  margin-top: 2rem;
}

/* .new_users a {
  color: #FF4820;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.new_users a:hover {
	color: #FF4820;
  text-decoration: underline;
	font-weight: bold;
} */


.signin__modal-close {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 40px;
  font-weight: bold;
  color: #aaa;
  cursor: pointer;
}

.signin__modal h2 {
	font-size: 30px;
}

.signin__modal-close:hover {
  color: #000;
}

.signin__form-group {
  margin-bottom: 20px;
}

.signin__label {
  display: block;
  /* font-weight: bold; */
  font-size: 18px;
	margin: 2rem 2rem 0 0;
	line-height: 1.5;
}

.signin__input {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  width: 100%;
}

.signin__input:focus {
  outline: none;
  border-color: #0070f3;
}

.signin__button[type="submit"] {
  background-color: #FF4820;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 15px 30px;
  font-size: 18px;
  cursor: pointer;
	display: flex;
	margin: 0 auto;
}

.signin__button[type="submit"]:hover {
  background-color: #FF4820;
}

@media screen and (max-width: 800px) {
	.signin__modal-content {
		width: 60%;
	}
}

@media screen and (max-width: 570px) {

	.signin__modal-content {
		position: relative;
		padding: 50px 20px;
		border: 1px solid #888;
		width: 100%;
		max-width: 100%;
		box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
		border-radius: 5px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	.signin__label {
		font-size: 14px;
		display: flex;
		align-items: flex-start;
	}
	
	.signin__input {
		font-size: 14px;
		padding: 8px;
	}

	.signin__form-group {
		margin-bottom: 30px;
	}
}