.signup__button {
	padding: 0.5rem 1rem;
	color: #fff;
	background: none;
	font-family: var(--font-family);
	font-weight: 500;
	font-size: 18px;
	line-height: 25px;
	border-radius: 5px;
	border: none;
	outline: none;
	cursor: pointer;
}

.signup__modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.signup__modal h2 {
	font-size: 30px;
}

.signup__modal-content {
  background-color: #fff;
  padding: 30px;
  border-radius: 5px;
  position: relative;
  width: 400px;
}

.signup__modal-close {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
  color: #aaa;
}

.signup__modal-close:hover {
  color: #000;
}

.signup__label {
  display: block;
  font-size: 18px;
	margin: 2rem 2rem 0 0;
	line-height: 1.5;
}

.signup__input {
	padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  width: 100%;
}

.signup__form-group:last-child {
  margin-bottom: 0;
}

.signup__form-group {
  margin-bottom: 20px;
}

.signup__button[type="submit"] {
  background: var(--gradient-bar);
  color: #fff;
  font-size: 18px;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
	display: block;
	margin: 0 auto;
	text-decoration: none;
}

@media screen and (max-width: 800px) {
	.signup__modal-content {
		width: 60%;
	}
}

@media screen and (max-width: 570px) {
	.signup__modal-content {
		width: 100%;
		padding-top: 4rem;
	}

	.signup__modal-content h2 {
		font-size: 30px;
		display: flex;
		align-items: flex-start;
	}

	.signup__label {
		font-size: 14px;
		display: flex;
		align-items: flex-start;
	}
	
	.signup__input {
		font-size: 14px;
		padding: 8px;
	}

	.signup__button[type="submit"] {
		text-decoration: none;
	}
}

@media screen and (max-width: 400px) {
	.signup__modal-content {
		width: 300px;
	}

	.signup__label {
		font-size: 17px;
		display: flex;
		align-items: flex-start;
	}

	.signup__input {
		font-size: 20px;
	}
}